body {
  color: $dark;
  font-size: 16px;
  line-height: 28px;
  font-family: 'Open Sans';
}

a {
  color: $primary;
}

h1,.h1 {
  color: $primary;
  font-size: 52px;
  font-weight: 600;
  line-height: 64px;
}

h2,.h2 {
  color: $primary;
  font-size: 42px;
  line-height: 54px;
}

h3, .h3 {
  color: $primary;
  font-size: 36px;
  line-height: 46px;
}

h4, .h4 {
  color: $primary;
  font-size: 32px;
  line-height: 42px;
}

h5, .h5 {
  color: $primary;
  font-size: 26px;
  line-height: 36px;
}

h6, .h6 {
  color: $primary;
  font-size: 20px;
  line-height: 32px;
}

blockquote {
  color: $primary;
  font: normal normal normal 26px/36px Open Sans;
}

blockquote site {
  color: $primary;
  font: normal normal normal 18px/28px Open Sans;
  letter-spacing: 0px;
}

body {
  &.elementor-kit-42 {
    h1 {
      font-size: 46px;
      line-height: 56px;

      @media only screen and (max-width: 1024px) {
        font-size: 38px;
        line-height: 48px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
}