$primary: #0e48a1;
$accent: #95bd13;
$info:#408df5;
$dark:#2c2f34;
$muted: #808285;
$light: #E8E8E8;
$offwhite: #f7f7f7;
$theme-colors:(
  "primary": $primary,
  "accent": $accent,
  "info": $info,
  "dark": $dark,
  "muted": $muted,
  "light": $light
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1320px
);