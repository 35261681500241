@media screen and (max-width: 1200px) {
  [data-elementor-type="wp-page"] {
    overflow: hidden;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1300px) {
  section.elementor-section:not(.elementor-inner-section) {
    > .elementor-container {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
  main {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  section.elementor-section:not(.elementor-inner-section) {
    > .elementor-container {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
  main {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .searchbar button {
    min-width: 174px;
  }
  #menu-primary {
    a {
      font-size: 16px;
      margin-left: 30px;
    }
  }
  #top-nav li:not(.btn) a,
  #top-nav li:not(.btn) a span {
    color: $primary !important;
  }
  .dual-image {
    &:before {
      height: 412px;
      width: 557px;
      top: -20px;
    }
    right: -1rem;
    .elementor-element {
      width: 250px;
      height: 270px;
      img {
        width: 200px;
      }
      &:first-child {
        top: 0;
        right: 35px;
        img {
          position: absolute;
          top: 0;
          left: 110px;
        }
      }
      &:last-child {
        position: absolute;
        top: 66px !important;
        right: 0;
        left: auto;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  section.elementor-section:not(.elementor-inner-section) {
    > .elementor-container {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }

  section.bg-info {
    .elementor-image {
      width: auto;
      border-radius: unset;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 0 0 6px 6px;
    }
  }

  #main-header {
    background: $primary;
    width: calc(100% + 32px);
    margin: 0 -16px;
    padding: 0 !important;
    min-height: 55px;
    position: relative;
    z-index: 1000;

    #mobile-login-link {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 55px;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    #mobile-logo-link {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      width: auto;
      text-align: center;
      line-height: 0;
    }

    .navbar-toggler {
      margin: 0 !important;
      margin-top: 0 !important;
      border: 0;
      padding: 0;
      color: #fff;
      z-index: 1;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
      height: 55px;
      width: 32px;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    .navbar {
      z-index: 1;
      color: #fff;
      height: 55px;
    }

    #navbarNav {
      height: calc(100vh - 55px);
      top: 55px;
      margin: 0 -16px;
      background: #0e48a1;
      z-index: 100000;
      padding: 0 32px;
      position: absolute;
      width: calc(100% + 4px);
    }

    #menu-primary {
      margin: 0 -27px;
      padding: 0 27px !important;
      border-top: 1px solid #3e6db4 !important;

      li {
        border-bottom: 1px solid #3e6db4 !important;
      }
      
      a {
        margin-left: 0;
        font-size: 18px;
        height: 63px;
        line-height: 63px;
        
        span {
          color: #fff;
        }
      }
    }

    #signup {
      margin-top: 26px;
    }

    #login {
      display: block;
      margin-top: 35px;
      line-height: 2rem;
    }
    #mobile-nav {
      a {
        color: #fff;
        line-height: 1.5rem;
      }
    }
    #mobile-nav-menu li a {
      // line-height: 2;
    }
  }

  #navbarNav {
    flex-direction: column;

    #menu-primary a {
      font-size: 16px;
      margin-left: 0;
    }
  }
  #logo-link {
    margin-top: -30px;
  }
}
@media screen and (max-width: 768px) and (min-width: 576px) {
  #joblist {
    .job {
      flex-wrap: wrap;
      .item {
        margin-bottom: 32px;
        &.name,
        &.location {
          flex-basis: 50%;
        }
        &.link {
          flex-basis: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
  }
  .tablet-order--1 {
    order: -1;
  }
}
@media screen and (max-width: 768px) {
  .tags a {
    margin: 5px;
  }
  .blog-header {
    margin-top: 50px;
  }
  .blog.single {
    padding-top: 0;
    .container {
      padding: 0 15px;
    }
    .back {
      margin-top: 40px !important;
    }
  }
  .menu-white {
    ul {
      a {
        font-size: 15px;
        line-height: 32px;
      }
    }
  }
  .bg-faces {
    margin-left: 40px !important;
    margin-right: 40px !important;
    overflow: visible !important;
    border-radius: 6px;
  }
  .elementor-widget-button .elementor-button-link.elementor-size-lg,
  .btn-lg {
    min-width: 172px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  section.bg-info {
    margin-left: 40px !important;
    margin-right: 40px !important;
    img {
      border-radius: 6px 6px 0 0;
    }
    .elementor-image {
      // max-height: 450px;
      max-height: 440px;
      max-width: 100%;
    }
  }
  .checklist {
    ul {
      li {
        font-size: 17px;
        line-height: 40px;
      }
    }
  }
  .grafter-circle.radiate {
    &:before {
      background: url(img/tablet-radiate2.png) no-repeat top center;
      width: 100%;
      height: 100%;
      top: -289px;
      left: 0;
      background-size: contain;
    }
  }
  // body {
  //     font-size: 19px;
  //     line-height: 30px;
  // }

  #top-nav {
    top: 20px;
    position: relative;
    padding-right: 50px;
    li {
      height: 30px;
      a {
        color: $primary;
        font-size: 16px;
        padding: 0;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .dual-image {
    right: 0;
    left: auto;
    height: 392px;
    &:before {
      // display: none !important;
      top: -50px;
      width: 270px;
      height: 270px;
      right: 0;
    }
    .elementor-widget-wrap {
    }
    .elementor-element {
      &:first-child {
        top: 0;
        right: -50px;
        left: auto;
        img {
          width: 220px;
          height: 220px;
        }
      }
      &:last-child {
        right: 0;
        top: -50px;
        bottom: auto;
        img {
          width: 220px;
          height: 220px;
        }
      }
    }
  }

  .searchbar {
    margin-top: 18px;
    select,
    input,
    button,
    .select2-selection {
    }
    select,
    input[name="job_title"] {
      border-radius: 6px 0 0 6px;
      flex-grow: 1;
      min-width: 288px;
    }
    .form-group.postcode {
      flex-grow: 1;
    }
    input[name="postcode"] {
      border-radius: 0 6px 6px 0;
      min-width: 178px;
      width: 100%;
    }
    button {
      width: 178px;
    }

    .select2-selection {
    }
    .select2-selection__rendered {
      width: 100px;
    }
    .select2-selection__arrow {
    }
  }
  .elementor-text-editor {
    p {
      br {
        display: none;
      }
    }
  }
  .checklist {
    ul {
      li {
        line-height: 30px;
        &:before {
          top: 5px;
        }
        margin-bottom: 5px;
      }
    }
  }
  section.elementor-section:not(.elementor-inner-section) {
    > .elementor-container {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
  .card-deck {
    &.cards-4,
    &.cards-3 {
      flex-wrap: wrap;
      margin-bottom: -1rem;
      > * {
        flex-basis: calc(50% - 3.5rem) !important;
        flex-grow: 0;
        margin-bottom: 2rem;
      }
    }
    &.cards-3 {
      > * {
        flex-basis: calc(50% - 2rem) !important;
      }
    }
  }

  .bg-faces {
    overflow: hidden;

    &:before {
      background: url("img/bottom-left.png.webp") no-repeat bottom left;
      left: -41px;
      bottom: -40px;
    }
  }

  .grafter {
    .rating {
      display: block;
      
      span {
        display: block;
      }
    }
  }

  #grafter-list {
    .role {
      margin-bottom: 2rem !important;
      width: calc(50% - 20px);
      flex-grow: 1;
      
      .inner {
        max-width: calc(100% - 20px);
        max-height: 400px;
        overflow: hidden;
        border-radius: 6px;
      }
      
      img {
        width: 100%;
      }
    }
  }

  .packages {
    .elementor-column {
      margin: 0 !important;
      padding: 0 10px !important;
    }
  }

  .shadow-box {
    &.normal {
      width: 85% !important;
    }
  }

  .blog-header {
    margin-top: 30px;
  }

  .archive {
    .post {
      margin-bottom: 77px;
      
      .bd {
        padding: 20px;
      }
      
      .img {
        height: auto;
      }
    }
  }

  .wp-pagenavi {
    margin-bottom: 50px !important;
  }
  .blog.single {
    padding-top: 0;

    .container {
      padding: 0 15px;
    }

    .back {
      margin-top: 0;
    }

    .tags {
      margin-bottom: 50px;
    }
  }
}

@media screen and (min-width: 769px) {
  .blog.single {
    .img {
      margin: 0 -190px 54px;
    }
  }

  .yarpp-related {
    margin: 0 -190px !important;
  }

  #grafters {
    .grafter:nth-child(4) {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  section.bg-info img {
    width: 100% !important;
    height: auto !important;
  }

  [data-elementor-type="header"] {
    background: $primary;
    
    .elementor-widget-wrap {
      padding: 0 !important;
    }
  }

  .dual-image {
    order: -1;
    height: 260px;
    margin: 30px 0 -240px auto !important;
    width: 250px !important;
    margin-left: auto;
    position: relative;
    opacity: 0.2;
    display: none !important;
   
    &:before {
      top: -20px;
      width: 120vw;
      margin-right: -1rem;
    }

    .elementor-element {
      height: auto;
      
      &:first-child,
      &:last-child {
        position: static;
        
        img {
          width: 150px;
          height: auto;
        }
      }

      &:last-child {
        img {
          width: 150px;
          height: auto;
        }
      }
    }
  }

  .searchbar {
    form {
      .bd {
        flex-direction: column;
        input,
        select,
        button,
        .select2-container {
          width: 100%;
          height: 50px;
        }

        input {
          border-radius: 6px 6px 0px 0px;
        }

        .postcode input {
          border-radius: 0 0 6px 6px;
        }

        button {
          margin: 0;
          flex-grow: 0;
          flex-basis: 56px;
        }
      }

      .select2-selection__rendered {
        width: 100%;
      }
    }
  }

  .shadow-box {
    box-shadow: 0px 5px 15px #0000000d;
  }

  section.bg-info:first-child {
    margin: -51px 32px 0 !important;
    border-radius: 6px;
    
    img {
      border-radius: 6px 6px 0 0;
    }
  }

  .checklist {
    ul {
      li {
        font-size: 15px;
        line-height: 21px;
        padding-left: 27px;
        &:before {
          background-size: cover !important;
          width: 18px;
          height: 18px;
          top: 2px;
          margin-right: 8px;
        }
      }
    }
  }

  .elementor-widget-button {
    &.float-left,
    &.float-right {
      .elementor-button-link.elementor-size-lg {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }

  .elementor-widget-button .elementor-button-link.elementor-size-lg,
  .btn-lg {
    height: 50px;
    padding: 12px 0;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .elementor-element.float-left {
    width: auto !important;
    max-width: 49% !important;
  }

  .shadow-box {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  #reviews {
    .carousel {
      margin-right: 0;
    }
  }

  .card-deck {
    &.cards-4,
    &.cards-3 {
      > *,
      .card {
        flex-basis: 100% !important;
        width: 100%;
      }
    }
  }

  .blog-header {
    margin-top: 30px;
  }

  .blog.single {
    .back {
      margin-top: 30px;
    }

    .container {
      p {
        font-size: 17px !important;
        line-height: 27px !important;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .bg-faces {
    border-radius: 6px;
    margin-left: 31px !important;
    margin-right: 31px !important;
    overflow: visible;

    &:before {
      background: url("img/mobile-faces1.png") no-repeat bottom center;
      background-size: 95% auto;
      left: -31px;
      bottom: -35px;
      width: 100vw;
    }

    &:after {
      display: none;
      background: $info;
      width: calc(100% - 62px);
      left: 31px;
      border-radius: 6px;
    }
  }

  .mobile-order--1 {
    order: -1;
  }

  ul.locations {
    column-count: 2;
    column-width: 50%;
  }

  footer,
  .elementor-location-footer {
    .menu-white {
      text-align: center;

      a {
        font-size: 14px;
        line-height: 32px;
        text-align: center;
      }
    }

    ul.roles,
    ul.locations {
      column-count: 2;
      column-width: 50%;
      margin-bottom: 20px;

      li {
        line-height: normal;
      }

      a {
        font-size: 13px;
        line-height: normal;
      }
    }
  }

  #copyright {
    .container,
    .elementor-widget-container {
      flex-direction: column;
      padding: 26px 0 28px;
      font-size: 13px;
      line-height: 25px;
      height: auto;
    }

    #social-icons {
      margin-bottom: 16px;
    }
  }

  .grafter-circle {
    &.radiate {
      &:before {
        display: none;
      }

      &:after {
        background: url("./img/mobile-circles1.png") no-repeat top left;
        top: -161px;
        left: 0;
        background-size: contain;
        // display: none;
      }
    }
  }

  #top-nav {
    li {
      &:not(.btn) {
        margin-top: 10px;

        a {
          background: $primary !important;
          border-radius: 4px;
        }
      }

      &:not(.btn) a,
      #top-nav li:not(.btn) a span,
      a {
        text-align: center;
        margin: 0;
      }
    }
  }

  #grafter-list {
    .role {
      margin-bottom: 26px !important;
      width: calc(100%) !important;

      h4 {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
        left: 10px;
        padding: 0 20px;
        width: calc(100% - 20px);
      }

      width: 100%;

      .inner {
        max-width: unset;
      }

      img {
        width: 100%;
      }
    }
  }

  #grafters {
    h2 {
      margin-bottom: 30px;
    }
  }

  .elementor-accordion {
    margin: 0 -0.5rem;

    .elementor-accordion-item {
      padding: 0 !important;
    }

    .elementor-tab-title {
      padding: 16px 20px 4px !important;

      &.elementor-active {
        border-radius: 6px 6px 0 0;
        border: 1px solid #e8e8e8 !important;
        border-bottom: 0 none !important;
        background: #fff;
      }

      a {
        font-size: 16px !important;
      }
    }

    .elementor-tab-content {
      border: 1px solid #e8e8e8;
      border-radius: 0 0 6px 6px;
      border-top: none;
      font-size: 15px !important;
      padding: 9px 20px 25px !important;

      &.elementor-active {
        background: #fff;
      }
    }
  }

  .packages {
    .elementor-column {
      margin: 0 0 31px !important;
      padding: 0 !important;
    }
  }

  .elementor-column-gap-default
    > .elementor-column
    > .elementor-element-populated {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .shadow-box {
    &.normal {
      width: 100% !important;
    }
  }

  .center-line {
    padding-top: 80px;

    &,
    &.complete,
    &.first,
    &.complete.first,
    &.last.complete {
      &:before {
        top: 0 !important;
        height: 40px !important;
        z-index: 10;
      }
    }

    &:after {
      top: 40px;
    }

    .elementor-container {
      &:before {
        display: none;
      }
    }

    &.muted {
      h3.elementor-heading-title {
        &:before {
          border-color: $light;
        }
      }
    }
  }
  #joblist {
    .job {
      padding: 0 0 10px;
      flex-direction: column;

      .item {
        display: flex;
        align-items: start;
        width: 100%;
        padding: 0 10px;

        &:first-child {
          background: #f1f1f1;
          padding-top: 10px;
          padding-bottom: 5px;
        }

        &:nth-child(even) {
          background: #f5f5f5;
        }

        strong {
          display: inline-block;
          width: 130px;
          font-size: 14px;
        }

        .value {
          display: inline-block;
          font-size: 14px;
          width: calc(100% - 100px);
        }

        &.link {
          margin-top: 10px;
          width: 100%;

          strong {
            display: none;
          }

          .value {
            width: 100%;
          }

          a {
            width: 100%;
          }
        }
      }

      .name {
        width: auto !important;
      }
    }
  }

  .job-pagination {
    margin: 40px 0 80px;
  }

  #apply-now {
    padding: 20px 10px 15px;

    h5 {
      margin-bottom: 0;
    }

    .items {
      .item {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .btn {
      margin-bottom: 5px;
    }

    .login {
      font-size: 16px;
    }
  }

  .blog-header {
    display: block !important;

    h1 {
      font-size: 30px;
      line-height: 42px;
    }
  }

  .alljobs {
    margin-top: 0px;
  }

  input[type="text"],
  input[type="email"],
  input[type="phone"],
  select {
    height: 40px;
    line-height: 40px;
  }

  .blog-header {
    select {
      width: 100%;
    }
  }

  .tags {
    margin-top: 5px;

    a {
      margin-right: 15px;

      &:first-child {
        margin-left: 15px;
      }
    }
  }

  .archive {
    .post {
      .bd {
        padding: 10px;

        h2 {
          min-height: unset;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  p {
    font-size: 17px;
    line-height: 27px;
  }

  .menu-white {
    ul {
      li {
        text-align: left;
      }

      a {
        display: block;
        line-height: 24px;
      }
    }
  }
}

@media screen and (min-width: 568px) {
  .elementor-element.float-left {
    width: auto !important;
    max-width: 49%;
  }
}

@media screen and (max-width: 782px) {
  html[lang] {
    margin-top: 0 !important;
  }

  body.admin-bar {
    padding-top: 46px;
  }
}
