@import '_variables.scss';
@import '../../node_modules/bootstrap-scss/bootstrap.scss';
@import 'typography';

// Select2
@import '../../node_modules/select2/src/scss/core.scss';

/* Bdy */
body {
    // max-width: 100vw;
    max-width: 1500px;
    margin: 0 auto;
    overflow-x: hidden;
    margin: 0 auto;
    position: relative;
}
main {
    // background: #f7f7f7;
    padding-bottom: 74px;
}

/* Header */
#logo {
    width: 164px;
}
/* Nav */
nav.navbar {
    padding: 0;
}

button.navbar-toggler:focus {box-shadow: none;}

#navbarNav {
    margin: 30px 0;

	@media only screen and (min-width: 992px) {
		justify-content: space-between;
	}
}
#menu-primary {
    // margin-left: 50px;
    li {
        padding: 0;
    }
    a {
        margin-left: 50px;
        padding: 0;
        span {
            color: $primary;
            // font-weight: 600;
        }
    }
}
#top-nav {
    z-index: 10;
    li {
        padding: 0 !important;
        border: 0 none;
        a {
            border: 0 none;
            color: $primary;
            // font-weight: 600;
            font-size: 18px;
            padding:  0 15px;
            height: 40px;
            line-height: 40px;
        }
    }
    li:not(.btn) {
        a {
            margin-left: 30px;
            &, span {
                color: #fff !important;
                // background: #fff;
                // display: inline-block;
                // margin: 0 10px;
                // padding: 0 20px;
                // border-radius: 5px;
            }
        }
    }
}

/* Buttons */
.btn-accent {
    color: #fff;
}

/* Elements */
.shadow-box {
    .elementor-widget-container {
        padding:0 !important;
    }
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #0000000D;
    border: 1px solid #E8E8E8;
    border-radius: 6px;

    box-shadow: 0px 1px 6px #76767652;
    border-radius: 10px;
    padding: 20px;
    p:last-child {
        margin-bottom: 0;
    }
    a {
        text-decoration: underline !important;
        color: #5473D3;
    }
    &.extended {
        margin: 0 -50px;
        width: calc(100% + 100px) !important;
        z-index: 10;
    }
    &.normal {
        // margin: 0 -100px;
        width: calc(100% - 400px) !important;
        z-index: 10;
    }
}

/* Dark areas */
.bg-primary {
    color: #fff;
}




h2 {
    // font-size: 52px;
    // line-height: 64px;
    font-weight: normal;
}

/* Dual Image */
.dual-image {
    height: 600px;
    position: relative;
    right: -120px;
    &:before {
        background-color: $primary;
        background-size: 610px 700px;
        background-position: top right;
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 700px;
        width: 610px;
        right: -210px;
        top: -130px;
        position: absolute;
        // z-index: -1;
    }
    .elementor-element {
        width: 400px !important;
        height: 440px;
        overflow: hidden;
        &:first-child {
            position: absolute;
            top: 0;
            right: 220px;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &:last-child {
            position: absolute;
            top: 160px;
            right: 0;
            img {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}

.bg-faces {
    // margin: 0 100px;
    background: $info;
    max-width: 1280px;
    margin: 0 auto 40px;
    &:before {
        background: url('img/bottom-left.png.webp') no-repeat bottom left;
        left: -41px;
        bottom: -40px;
    }
    &:after {
        background: url('img/bottom-right.png.webp') no-repeat bottom right;
        right: -60px;
        bottom: -40px;
        z-index: 0;
    }
    > * {
        z-index: 1;
    }
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

/* Bg Info */
.bg-info {
    p {
        color: #fff !important;
    }
}
.dual-image .elementor-image img {
    width: 100%;
}

/* Card Decks */
.card-deck.cards-4 {
    display: flex;
    .card {
        width: 25%;
    }
}
.card-deck.cards-3 {
    display: flex;
    .card {
        width: 33%;
    }
}

/* Grafters List */
#grafter-list {
    max-width: 1060px;
    margin: 0 auto;
    justify-content: space-between;
    .role {
        margin-bottom: 95px;
        .inner {
            max-width: 290px;
            position: relative;
        }    
        width: calc(33% - 1rem);
        img {
            border-radius: 6px;
            width: 290px;
            height: auto;
        }
        h4 {
            background: #fff;
            border-radius: 6px; 
            color: $primary;
            height: 56px;
            line-height: 56px;
            position: absolute;
            padding: 0 20px;
            font-size: 18px;
            bottom: 20px;
            left: 20px;
            margin-bottom: 0;
            width: calc(100% - 40px);
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

#joblist {
    // background: #fff;
    // border: 1px solid #E8E8E8;
    // border-radius: 6px;
    .job {
        background: #fff;
        box-shadow: 0px 5px 15px #0000000D;
        border: 1px solid #E8E8E8;
        border-radius: 6px;
        padding: 26px 31px 26px 30px;
        margin-bottom: 10px;
        strong {
            font: normal normal 600 16px/24px Open Sans;
            letter-spacing: 0px;
            color: $muted;
        }
        .item {
            width: 200px;
            &.name {
                width: 300px;
            }
            &.category {
                width: 300px;
            }
            .value {
                font: normal normal normal 16px/24px Open Sans;
                letter-spacing: 0px;
                color: #2C2F34;
            }
            &.link {
                strong {
                    display: none;
                }
                a {
                    background: #F7F7F7 0% 0% no-repeat padding-box;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 106px;
                    height: 56px;
                    
                }
            }
        }
    }
}

/* Single Job */
#apply-now {
    .items {

        .item {
            width: 50%;
            border-bottom: 1px solid #E8E8E8;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }

	.btn {
		width:100%;
	}

    .apply-for-job {
        color: #fff;
        text-decoration: none !important;
    }
}


/* Blog */

.archive .post {
    margin-bottom: 87px;
    .readmore-area {
        margin-top: 10px;
        .date {
            font: normal normal normal 16px/26px Open Sans;
            letter-spacing: 0px;
            color: $muted;
        }
    }
    .img {
        background: #e8e8e8;
        // height: 340px;
        margin: 0 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center;
        overflow: hidden;
        a,
        img {
            width: 100%;
            height: auto;
        }
    }
    .entry-content {
        min-height: 168px;
    }
    .bd {
        border: 1px solid #E8E8E8;
        // border: 1px solid $primary;
        border-radius: 0px 0px 6px 6px;
        padding: 41px 40px 49px 40px;
        h2 {
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
            margin-bottom: 16px;
            min-height: 72px;
        }
        p {
            font-size: 18px;
            font-weight: normal;
            line-height: 28px !important;
            letter-spacing: 0;
        }
    }
}
.tags {
    margin-top: 25px;
    font: normal normal normal 15px/21px Open Sans;
    a {
        font: normal normal 600 15px/21px Open Sans;
        letter-spacing: 0px;
        color: #0E48A1;
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border-radius: 6px;
        display: inline-block;
        margin-right: 30px;
        &:first-child {
            margin-left: 30px;
        }
    }
}
.blog-header {
    margin-top: 90px;
    margin-bottom: 33px;
    align-items: center;
}

select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
    color: $primary;
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
    height: 56px;
    line-height: 56px;
}

/* Page Navi */
.wp-pagenavi {
    text-align: center;
    a,
    span {
        border: 0 none !important;
        font: normal normal 600 18px/28px Open Sans;
        letter-spacing: 0px;
        color: #5473D3;
    }
    a {
        text-decoration: underline;
    }
    .current {
        color: #2C2F34;
    }
}

.archive .wp-pagenavi {
    margin-bottom: 138px;
}

.blog.single {
    .container {
        padding: 0 190px;
    }
    .back {
        margin-top: 111px;
        margin-bottom: 16px;
        display: block;
        i {
            position: relative;
            top: 3px;
        }
    }
    .excerpt {
        p {
            font: normal normal normal 22px/32px Open Sans;
            letter-spacing: 0px;
        }
        margin-bottom: 28px;
    }
    .img {
        // margin: 0 -190px 54px;
        // width: 100%;
        display: flex;
        justify-content: center;
        img {
            max-width: 1140px;
        }
    }
    .date {
        font: normal normal normal 18px/28px Open Sans;
        letter-spacing: 0px;
        color: $muted;
        margin-bottom: 55px;
    }
    .tags {
        border-top: 1px solid #E8E8E8;
        padding-top: 25px;
        margin-top: 54px;
        margin-bottom: 142px;
    }
}


#apply-now {
    box-shadow: 0px 5px 15px #0000000D;
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    padding: 32px 30px;
    .item {
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 20px;
        margin-bottom: 20px;
        strong {
            font: normal normal 600 16px/24px Open Sans;
            letter-spacing: 0px;
            color: $muted;
        }
        .value {
            font: normal normal normal 16px/24px Open Sans;
            letter-spacing: 0px;
            color: #2C2F34;
        }
        &:last-child {
            border-bottom: 0 none;
            margin-bottom: 5px;
        }
    }
    .btn {
        margin-bottom: 25px;
    }
    .login {
        font-size: 16x;
        line-height: 24px;
    }
}

.elementor-widget-button .elementor-button,
.btn {
    font-size: 18px;
    font-weight: 600 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    // line-height: 24px;
    // height: 32px;
}
.elementor-widget-button .elementor-button.elementor-button-link-size-lg,
.btn-lg {
    font-size: 18px;
    height: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.alljobs {
    border-radius: 0 none;
    background:#fff;
    color: $primary;
    display: inline-block;
    // margin-top: 55px;
    text-align: center;
    margin: 55px auto 0;

}
.center-line {
    // overflow: hidden;
    .elementor-container {
        &:before {
            // transition: 2s height;
            border-right: 2px solid $light;
            margin-right: -1px;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
        }
        
    }
    &.last.complete {
        &:before {
            height: 155% !important;
        }
    }
    &:before {
        transition: 2s height;
        border-right: 2px solid $light;
        margin-right: -1px;
        display: block;
        content: '';
        width: 50%;
        height: 0;
        position: absolute;
        left: 0;
        top: -55%;
        
    }
    &.half:before  {
        border-right: 2px solid $info;
        height: 50%;
    }
    &.complete:before  {
        border-right: 2px solid $info;
        height: 105% !important;
        z-index: 1;
    }
    &.complete.first:before  {
        height: 50% !important;
        top: 0 !important;
    }
    &.dotted:after  {
        border-color: $info;
    }
    &.half {
        &:before {
            // border-color: $light;
            height: 50%;
        }
        .elementor-column:first-child {
            &:before {
                border-right: 2px solid $light;
                margin-right: -1px;
                display: block;
                // content: '';
                width: 100%;
                height: 50%;
                position: absolute;
                left: 0;
                top: 50%;  
            }
        }
        &:after {
            // border: 1px solid $light;
        }
    }
    &.muted {
        &:before {
            border-color: $light;
        }
        &:after {
            border: 1px solid $light;
        }
    }
    &:after {
        transition: 1s all;
        background: #fff;
        border: 10px solid $light;
        display: block;
        content: '';
        width: 26px;
        height: 26px;
        border-radius: 50%;
        // background: $info;
        position: absolute;
        right: calc(50% - 12px);
        top: calc(50% - 13px);
        z-index: 3;
    }
}

.packages {
    .elementor-column {
        margin: 0 20px !important;
        > .elementor-widget-wrap {
            padding: 32px 30px 39px 30px !important;
        }
    }
}
.checklist.blue ul {
    li {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%2395BD13' d='M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zm7.258 9.307-9.486 9.485c-.238.237-.623.237-.861 0l-.191-.191-.001.001L7.5 16.346c-.238-.238-.238-.624 0-.862l1.294-1.293c.238-.238.624-.238.862 0l3.689 3.716 7.756-7.756c.238-.238.624-.238.862 0l1.294 1.294c.239.237.239.623.001.862z'/%3E%3C/svg%3E");
        }
    }
}
.checklist ul {
    list-style: none;
    padding-left: 0;
    li {
        padding-left: 30px;
        position: relative;
        &:before {
            display: inline-block;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%2395BD13' d='M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zm7.258 9.307-9.486 9.485c-.238.237-.623.237-.861 0l-.191-.191-.001.001L7.5 16.346c-.238-.238-.238-.624 0-.862l1.294-1.293c.238-.238.624-.238.862 0l3.689 3.716 7.756-7.756c.238-.238.624-.238.862 0l1.294 1.294c.239.237.239.623.001.862z'/%3E%3C/svg%3E");
            background-size: 22px 22px;
            background-position: center center;
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            position: absolute;
            top: 4px;
            left: 0;
        }
    }
}


/* Reviews */
.rating {
    i {
        color: #EFA736 !important;
        margin: 0 3px;
        font-size: 16px;
    }
}
#reviews {
    .carousel {
        margin-right: calc(640px - 100%);
        .carousel-controls {
            right: 640px;
        }
    }
    h3 {
        font-size: 52px;
        font-weight: 600;
        line-height: 64px;
    }
    .heading {
        margin-bottom: 13px;
    }
    .rating {
        margin-bottom: 31.2px;
    }
    .stats {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 42px;
        span {
            display: inline-block;
            margin: 0 7px;
        }
        strong {
            font-weight: normal;
        }
    }
}
.review.card {
  box-shadow: 0px 5px 15px #0000000D;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 30px;
  
  h3 {
    font-size: 24px !important;
    line-height: 34px !important;
  }

  .rating {
    margin-bottom: 15px;
  }
  
  p {
    margin-bottom: 0;
  }

  .date {

  }
}


/* Accordion */
.elementor-accordion {
    // border-top: 1px solid $light;
    
    .elementor-accordion-item {
        box-shadow: 0px 5px 15px #0000000D;
        border-radius: 6px;
        border: 0 none !important;
        background: $offwhite;
        // background: $light;
        padding: 0 30px !important;
        &:first-child {
            .elementor-tab-title {        
                // padding-top: 0 !important;
            }
        }
        margin-bottom: 10px;
    }

    .elementor-tab-title {
        .elementor-accordion-icon {
            float: right !important;
            .fas.fa-chevron-up {
                color: $primary;
            }
        }
        position: relative;
        border: 0 none !important;
        
        i {
            // display: none;
            
        }
        a {
            color: $primary !important;
            font-size: 20px !important;
            line-height: 30px !important;
            font-weight: normal !important;
            // &:after {
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     right: 20px;
            //     top: 32.5px;
            //     background: url('img/icons/sprite.png.webp') no-repeat top center;
            //     width: 18.52px;
            //     height: 11.26px;

            //     background-position: -41px 0;
            //     width: 19px;
            //     height: 11px;
            // }
        }
        &.elementor-active {
            a {
                &:after {
                    // background: url('img/chevron-up.svg') no-repeat top center;
                    background-position: -22px 0;
                }
            }
        }
        padding: 20px 0 !important;
        &.elementor-active {
            // box-shadow: 0px 5px 15px #0000000D;
            // background: red;
            // border-bottom: 1px solid $light !important;;
        }
        
    }
    .elementor-tab-content {
        border-top: 0 none !important;
        padding-top: 0 !important;
        padding-top: 30 !important;
        padding-left: 0 !important;
        font-size: 18px;
        line-height: 28px;
        ul {
            margin-left: 0;
        }
    }
}
.menu-white {
    ul {
        &,li {
            list-style: none;
            text-align: center;
            margin: 0 !important;
            padding: 0 !important;
        }
        a {
            font: normal normal normal 16px/34px Open Sans;
            letter-spacing: 0px;
            color: #D1D1D1;
            
        }
    }
}

ul.locations {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 4;
    column-width: 4;
}
footer,
.elementor-location-footer {
    // background: #34373c;
    // padding: 54px 0 6px;
	position: relative;
    padding-bottom: 0;
    margin-bottom: 0;
	z-index: 2;
    ul.roles,
    ul.locations {
        
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
        column-count: 4;
        column-width: 25%;
        a {
            font: normal normal normal 14px/28px Open Sans;
            letter-spacing: 0px;
            color: #D1D1D1;
        }
    }
    h1,h2,h3,h4,h5,h6,p, a{
        color: #fff;
    }   
    h6 {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
    }
}

#copyright {
    // border-top: 1px solid #54575b;
    color: #fff;
    background: $dark;
    
    font: normal normal normal 14px/25px Open Sans;
    .container,
    .elementor-widget-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 66px;
    }
    
}
#social-icons {
    display: block;
    a{
        &.facebook {
            background: url('./img/icons/facebook.png.webp') no-repeat top center;
            display: block;
            width: 26px;
            height: 26px;
            margin-right: 10px;
        }
        &.twitter {
            background: url('./img/icons/twitter.png.webp') no-repeat top center;
            display: block;
            width: 26px;
            height: 26px;
        }
    }
}

[data-elementor-type=wp-page] {
    .elementor-widget-container ul.locations {
        text-align: center;
        a {
            text-decoration: underline;
            font: normal normal normal 18px/28px Open Sans;
            letter-spacing: 0px;
            color: #5473D3;
        }
    }
}

/* Forms */
input[type=text],
input[type=email],
input[type=phone],
select {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
}

input[type=text],
input[type=email],
input[type=phone],
select,
textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
}
textarea {
    padding: 20px;
    flex-grow: 1;
}

form .right .bd {
    height: 100%;

}
.flex-grow-1 {
    flex-grow: 1;
}

.grafter-circle {
    &:before {
        background: url('./img/grafter-icon.svg');
        content: '';
        display: block;
        width: 70px;
        height: 69px;
        position: absolute;
        top: -35px;
        left: calc(50% - 35px);
    }
    &.radiate {
        z-index: -1;
        &:after {
            background:url('./img/grafter-circles.png.webp');
            content: '';
            display: block;
            max-width: 100%;
            width: 684px;
            height: 705px;
            position: absolute;
            top: -352.5px;
            left: calc(50% - 342px);
            z-index: -1;
        }
    }
}

/* Search Bar */
.searchbar {
    margin-top: 18px;
    select,
    input,
    button,
    .select2-selection {
        color: #808285;
        font-size: 16px;
        font-weight: normal;
        height: 56px;
    }
    select,
    input[name=job_title] {
        border: 1px solid #D1D1D1;
        width: 255px;
    }
    input {
        border-radius: 0px 6px 6px 0px;
        border: 1px solid #D1D1D1;
        width: 196px;
        padding: 0 20px;
        &.full {
            width: 35   0px;
        }
    }
    button {
        margin-left: 10px;
        width: 160px;
        flex-basis: 160px;
        color: #fff;
        padding: 0 !important;
    }

    .select2-selection {
        border: 1px solid #D1D1D1;
        border-radius: 6px 0px 0px 6px;
    }
    .select2-selection__rendered {
        height: 56px;
        line-height: 56px !important;
        padding: 0 20px !important;
        color: #808285 !important;
        
    }
    .select2-selection__arrow {
        top: 17px !important;
        right: 10px !important;
    }
    .form-group.postcode {
        position: relative;
        i {
            position: absolute;
            color: $primary;
            right: 8px;
            top: 10px;
        }
    }
}


.autocomplete-results {
  border: 2px solid #e4e4e4;
  border-radius: 0 0 6px 6px;
  background: #ffffff;
  color: #e4e4e4;
  padding: 0.5rem;
  overflow: auto;
  max-height: 40vh;
  position: absolute;
  min-width: 196px;
  z-index: 10;
}

.autocomplete-results .value {
  display: block;
  font-size: 14px;
  text-decoration: none;
}



/* Carousel */
.carousel-controls {
    position: absolute;
    right: 34px;
    top: 0;
    // background: red;
    // height: 100px;
    
    display: block;
    z-index: 100;

    .carousel-control-prev {
      padding-right: 3px;
      width: 38px;
      height: 38px;
    }

    .carousel-control-next {
      margin-left: 10px;
      padding-left: 3px;
      width: 38px;
      height: 38px;
    }

}   
.carousel {
    padding-top: 59px;
}
i.rounded {
    
}
.job-pagination {
    align-items: center;
    margin: 40px 0 150px;
    .current {
        font: normal normal 600 18px/28px Open Sans;
        letter-spacing: 0px;
        color: #2C2F34;
        display: inline-block;
        margin: 0 10px;
    }
    .page {
        text-decoration: underline;
        font: normal normal 600 18px/28px Open Sans;
        letter-spacing: 0px;
        color: #5473D3;
        display: inline-block;
        margin: 0 10px;
    }
}
a.rounded,
span.rounded {
    background: #cfdaec;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content:center;
    color: #fff;
    border-radius: 50% !important;
    position: static;
    width: 40px; 
    height: 40px;
    &:hover {
        background: $primary;
    }
}
span.rounded {
    &:hover {
        background: #cfdaec;
    }
}

/* Icons */
.icon-lightning {
    .elementor-icon-box-title {
        // background:url('./img/icons/lightning.png.webp') no-repeat top center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%23fff' d='m17.067 14 1.533-4h-3.9l-2.3 6h2.579L13.5 20.1l6.1-6.1h-2.533zm-3.522 3H11l3-8h6l-1.5 4H22L11 24l2.545-7z'/%3E%3C/svg%3E");
        background-size: 74px 74px;
        background-repeat: no-repeat;
        background-position: center -21px;
        padding-top: 50px;
    }
}
.icon-tracking {
    .elementor-icon-box-title {
        // background:url('./img/icons/links.png.webp') no-repeat top center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42' height='32'%3E%3Cpath fill='%23fff' d='M39 0c-1.654 0-3 1.346-3 3 0 .888.396 1.679 1.011 2.229l-8.8 14.031c-.371-.165-.78-.26-1.211-.26-.868 0-1.644.376-2.193.967l-9.073-5.745c.168-.374.266-.786.266-1.222 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .904.41 1.706 1.044 2.256L4.149 26.231C3.795 26.083 3.407 26 3 26c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.888-.395-1.678-1.01-2.228l6.904-10.99c.343.138.715.218 1.106.218.859 0 1.629-.367 2.176-.947l9.078 5.748c-.161.368-.254.772-.254 1.199 0 1.654 1.346 3 3 3s3-1.346 3-3c0-.863-.371-1.636-.957-2.184l8.81-14.046c.354.147.741.23 1.147.23 1.654 0 3-1.346 3-3s-1.346-3-3-3zM5 29c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm8-14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm14 9c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM39 5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z'/%3E%3C/svg%3E");
        // background-size: 44px 44px;
        background-repeat: no-repeat;
        background-position: center top ;
        padding-top: 58px;
    }
}
.icon-agreements {
    .elementor-icon-box-title {
        // background:url('./img/icons/check-page.png.webp') no-repeat top center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%23fff' d='M13 5V3.998C13 2.898 13.898 2 15.005 2h.99C17.106 2 18 2.895 18 3.998V5h2.004c.551 0 .996.447.996.999v1.002c0 .556-.446.999-.996.999h-9.009c-.551 0-.996-.447-.996-.999V5.999c0-.556.446-.999.996-.999h2.004zm-1-1h-.997c-1.104 0-2 .891-2.003 2H7.997C6.894 6 6 6.89 6 8.004v20.993c0 1.107.891 2.004 1.997 2.004h15.005c1.103 0 1.997-.89 1.997-2.004V8.004c0-1.107-.891-2.004-1.997-2.004h-1.003c-.003-1.105-.895-2-2.003-2h-.997c-.001-1.657-1.35-3-3.009-3h-.982C13.347 1 12 2.338 11.999 4zm10 3h1c.545 0 1 .449 1 1.003v20.994c0 .564-.448 1.003-1 1.003H8c-.545 0-1-.449-1-1.003V8.003C7 7.439 7.448 7 8 7h1c.003 1.105.895 2 2.003 2h8.994c1.104 0 2-.891 2.003-2zm-6.5-2c.276 0 .5-.224.5-.5s-.224-.5-.5-.5-.5.224-.5.5.224.5.5.5zm-1.246 18.685 7.778-7.778-.707-.707-7.071 7.071-3.743-3.743-.707.707 4.45 4.45z'/%3E%3C/svg%3E");
        background-size: 44px 44px;
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 58px;
    }
}
.icon-payments {
    .elementor-icon-box-title {
        // background:url('./img/icons/padlock.png.webp') no-repeat top center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cpath fill='%23fff' d='M15 21.915c-.583-.206-1-.762-1-1.415 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .653-.417 1.209-1 1.415v2.594c0 .263-.224.491-.5.491-.268 0-.5-.22-.5-.491v-2.594zm-1 .585v1.998c0 .829.666 1.502 1.5 1.502.828 0 1.5-.671 1.5-1.502V22.5c.607-.456 1-1.182 1-2 0-1.381-1.119-2.5-2.5-2.5S13 19.119 13 20.5c0 .818.393 1.544 1 2zM8 14v-3.501C8 6.356 11.358 3 15.5 3c4.134 0 7.5 3.358 7.5 7.499V14c1.659.005 3 1.35 3 3.009v9.981c0 1.673-1.347 3.009-3.009 3.009H8.009C6.346 29.999 5 28.652 5 26.99v-9.981c0-1.67 1.342-3.005 3-3.009zm1 0h1v-3.491C10 7.461 12.462 5 15.5 5c3.031 0 5.5 2.466 5.5 5.509V14h1v-3.507C22 6.907 19.083 4 15.5 4 11.91 4 9 6.908 9 10.493V14zm2 0h9v-3.499C20 8.015 17.98 6 15.5 6 13.015 6 11 8.009 11 10.501V14zm-3.003 1C6.894 15 6 15.897 6 17.006v9.988C6 28.102 6.891 29 7.997 29h15.005c1.103 0 1.997-.897 1.997-2.006v-9.988c0-1.108-.891-2.006-1.997-2.006H7.997z'/%3E%3C/svg%3E");
        background-size: 44px 44px;
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 58px;
    }
}





#not-found {
    margin: 100px auto;
}

.page-id-21,
.elementor-page-21 {
    #top-nav li:not(.btn) a, #top-nav li:not(.btn) a span {
        // background-color: #E8E8E8;
        color: $primary !important;
        // line-height: 1.5;
        font-weight: 600;
        // border-radius: 0.2rem;;
    }
}

.has-role {
    display: none;
}
body.role .has-role,
body.elementor-editor-active .has-role {
    display: block;
}



h4.musthave {
    margin-top: 45px;
}

section.bg-info {
    overflow: hidden;
    border-radius: 6px !important;
    margin-left: 80px !important;
    margin-right: 80px !important;
    .elementor-column {
        border-radius: 6px;
    }
    .elementor-image {
        overflow: hidden;
        padding: 0;
        margin: 0;
        width: calc(100% + 1rem);
        border-radius: 0 6px 6px 0;
        
    }
    img {
        border-radius: 0 6px 6px 0;
        height: 600px;
        width: auto;
        max-width: unset;
    }
}
.bg-info .elementor-icon-box-icon {
    height: 20px;
    margin-bottom: 25px !important;
    .elementor-icon {
        height: 20px;
    }
}
.fas.fa-quote-left {
    background: url('img/icons/quote.png.webp') no-repeat top center;
    // display: inline-block;
    width: 30px;
    height: 20px;
    &:before {
        display: none;
    }
}

/* Icons */
.fa-crosshairs,
.fa-chevron-left,
.fa-chevron-right,
.fa-chevron-up,
.fa-chevron-down,
.fa-plus,
.fa-minus {
    background: url('img/icons/sprite.png.webp');
    display: inline-block;
}
.fa-crosshairs {
    background: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath fill='%230e48a1' d='M10 .4C4.697.4.399 4.698.399 10c0 5.303 4.298 9.601 9.601 9.601 5.301 0 9.6-4.298 9.6-9.601S15.301.4 10 .4zm1 17.125V13H9v4.525C5.604 17.079 2.92 14.396 2.473 11H7V9H2.473C2.92 5.604 5.604 2.921 9 2.475V7h2V2.475c3.394.447 6.078 3.13 6.525 6.525H13v2h4.525c-.447 3.394-3.131 6.078-6.525 6.525z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 26px 26px;
    background-position: center center;
    height: 36px;
    width: 29px;
}
.fa-chevron-left {
    background-position: 0 0;
    width: 11px;
    height: 19px;
}
.fa-chevron-right {
    background-position: -11px 0;
    width: 11px;
    height: 19px;
}

.fa-chevron-up {
    background-position: -22px 0;
    width: 19px;
    height: 11px;
}
.fa-chevron-down {
    background-position: -41px 0;
    width: 19px;
    height: 11px;
}

.fa-crosshairs:before,
.fa-chevron-left:before,
.fa-chevron-right:before,
.fa-chevron-up:before,
.fa-chevron-down:before,
.fa-plus:before,
.fa-minus:before {
    display: none;
}
.single.single-post {
    main {
        h2 {
            font-size: 30px;
        }
    }
}

@import 'responsive';

.grafter-post-preview h2 {
  font-size: 32px !important;
  line-height: 42px !important;
  margin-bottom: 10px !important;
  min-height: 0 !important;
}

.grafter-post-preview h3 {
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 20px !important;
}

.tags a {
  font: normal normal 600 15px/21px Open Sans;
  letter-spacing: 0px;
  color: #0E48A1;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: inline-block;
  margin-right: 30px;
  padding: 2px 8px;
  margin: 0 4px 4px 0;
  font-size: 14px;
  border: 1px solid #0E48A1;
}

.archive .post .readmore-area {
  margin-top: 30px !important;
}

.single-post.elementor-kit-42 h1 {
  font-size: 44px !important;
  line-height: 50px !important;
}

.job-listing {
  > .col {
    &:last-of-type {
      .row {
        > div {
          position: initial;
        }
      }
    }
  }

  .position-absolute {
    transform: translateY(-50%);

    @media only screen and (min-width: 768px) {
      right: 20px;
      top: 50%;
    }
  }

  @media only screen and (max-width: 767px) {
    border-top: 4px solid $primary !important;
    
    > .col {
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        .col-4 {
          display: none !important;
        }

        .col-8 {
          width: 100% !important;
          flex: 0 0 100% !important;
          max-width: 100% !important;

          a {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .text-muted {
      font-size: 80%;
    }

    .position-absolute {
      position: relative !important;
      transform: none !important;
      top: auto !important;
      right: auto !important;
      width: 100%;
    }
  }
}

#reviews-carousel {
  padding-top: 0;

  .carousel-controls {
    top: -50px;
  }
}

.tiny-slider-controls {
  margin-bottom: 15px;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border: 0;
    background: none;
    padding: 0;
  }
}

#grafters {
  h2 {
    margin-bottom: 15px;
  }

  .none {
    font-size: 32px;
    font-weight: normal;
    margin: 40px 0;
  }
}

.grafter {
  .media {
    margin-bottom: 20px;

    .img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
      border: 1px solid #f0f0f0;
    }

    .media-body {
      margin-top: -3px;
    }
  }

  .details {
    margin-bottom: 20px;

    svg {
      position: relative;
      margin-top: -2px;
    }
  }
}

[data-elementor-type="wp-page"] {
	position: relative;
	z-index: 1;
}

.elementor-accordion-icon-closed {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='18' height='28' viewBox='0 0 18 28'%3E%3Cpath fill='%230e48a1' d='M16.797 11.5c0 0.125-0.063 0.266-0.156 0.359l-7.281 7.281c-0.094 0.094-0.234 0.156-0.359 0.156s-0.266-0.063-0.359-0.156l-7.281-7.281c-0.094-0.094-0.156-0.234-0.156-0.359s0.063-0.266 0.156-0.359l0.781-0.781c0.094-0.094 0.219-0.156 0.359-0.156 0.125 0 0.266 0.063 0.359 0.156l6.141 6.141 6.141-6.141c0.094-0.094 0.234-0.156 0.359-0.156s0.266 0.063 0.359 0.156l0.781 0.781c0.094 0.094 0.156 0.234 0.156 0.359z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
}

.elementor-accordion-icon-opened {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='18' height='28' viewBox='0 0 18 28'%3E%3Cpath fill='%230e48a1' d='M16.797 18.5c0 0.125-0.063 0.266-0.156 0.359l-0.781 0.781c-0.094 0.094-0.219 0.156-0.359 0.156-0.125 0-0.266-0.063-0.359-0.156l-6.141-6.141-6.141 6.141c-0.094 0.094-0.234 0.156-0.359 0.156s-0.266-0.063-0.359-0.156l-0.781-0.781c-0.094-0.094-0.156-0.234-0.156-0.359s0.063-0.266 0.156-0.359l7.281-7.281c0.094-0.094 0.234-0.156 0.359-0.156s0.266 0.063 0.359 0.156l7.281 7.281c0.094 0.094 0.156 0.234 0.156 0.359z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
}